<template>
  <div class="auth-page">
    <div class="w-100">
      <h1 class="text-center font-24 icon-black--text d-block mt-4">
        {{ verificationStatusText }}
      </h1>

      <!-- head -->
      <!-- <div class="text-center">
        <img
          class="cursor-pointer"
          @click="$router.push('/')"
          :src="require('@/assets/images/global/logo-blue.svg')"
          alt=""
        />
        <span class="font-24 icon-black--text d-block mt-4"
          >Verify your email</span
        >
      </div> -->

      <!-- content -->
      <!-- <div class="auth-page__content radius-15 pt-8 pb-4 px-5 mt-7">
        <v-form
          v-model="valid"
          ref="signinForm"
          @submit.prevent="submitHandler"
        >
          <div>
            <span class="d-block font-16 icon-black--text">Email Address</span>
            <base-input
              v-model="user.email"
              class="mt-1"
              placeholder="example@gmail.com"
            ></base-input>
          </div>

          <div class="mt-4">
            <span class="d-block font-16 icon-black--text"
              >Enter code that we sent to your email</span
            >
            <base-input
              v-model="user.code"
              class="mt-1"
              placeholder="K2E1DW1"
            ></base-input>
          </div>

          <v-btn
            class="mt-6 radius-15"
            height="45px"
            color="primary"
            elevation="0"
            block
            type="submit"
            ><span class="white--text font-16">Verify</span></v-btn
          >
        </v-form>
      </div> -->

      <!-- bottom btn -->
      <!-- <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn d-flex align-center justify-center"
      >
        <span class="font-16 icon-black--text d-inline-block mr-1"
          >New to Ofero?
        </span>
        <router-link to="/signup" class="d-flex">
          <span class="font-16 blue--text font-500">Create an account.</span>
        </router-link>
      </div> -->
      <!-- 
      <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn"
        @click="$router.push('/plans')"
      >
        <span class="font-16 icon-black--text font-16"
          >See Prices and plans
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    isAdmin: false,
    user: {
      email: "",
      code: "",
    },
    verificationStatusText: "Just a sec...",
  }),
  computed: {},
  mounted() {
    this.verifyEmail();
  },
  methods: {
    async submitHandler() {
      const res = await this.$axios.post(process.env.VUE_APP_VERIFY_EMAIL, {
        ...this.user,
      });
      if (res.status === 200) {
        this.$store.dispatch("setUser", res.data);
        this.$store.dispatch("showSnack", {
          text: "logged successfully",
          color: "success",
        });
        this.$router.push({
          path: res.data.isFirstLogin ? "/plans" : "/",
        });
        if (res.data.user.plan) {
          localStorage.setItem("plan", res.data.user.plan._id);
        } else {
          localStorage.removeItem("plan");
        }
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    verifyEmail() {
      console.log("start");
      this.$axios
        .post(
          `${process.env.VUE_APP_VERIFY_EMAIL}?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res.status === 200) {
            console.log("status", res.status);
            this.verificationStatusText = res.data.message;
            this.$store.dispatch("setUser", res.data);
            if (res.data.user.plan) {
              localStorage.setItem("plan", res.data.user.plan._id);
            } else {
              localStorage.removeItem("plan");
            }

            setTimeout(() => {
              this.$router.push({
                path: res.data.isFirstLogin ? "/plans" : "/",
              });
            }, 1000);
          }
        })
        .catch((err) => {
          console.log("error", err);
          this.verificationStatusText = "Something went wrong";
          this.$store.dispatch("showSnack", {
            text: err.message,
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  max-width: 380px;
  width: 100%;
  position: relative;
  padding: 4% 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__content {
    background: $bg-grey;
  }
}
</style>
